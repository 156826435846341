// TODO: create ic-js-form repo for this

import { createFetch, ContentFetch, ContentFetchOptions } from 'ic-js-util'
export interface Form {
	data: {
		clientId: string,
		title: string,
		description: string,
		createdDetails: {
			date: string,
		},
		displayOptions: {
			hideTitle: boolean,
		},
		heroMedia: {
			content: string,
		},
		id: string,
		lastEdited: {
			date: string,
		},
		linkedIds: [],
		publishFrom: string,
		publishTo: string,
		sections: {[key: string]: any}[],
		success: {[key: string]: any},
		tags: [],
		theme: {
			backgroundBorderColor: string,
			backgroundColor: string,
			backgroundRadius: string,
		},
	},
	status: string,
}

let contentFetch: ContentFetch

const init = () => {
	if (contentFetch) return
	const runtimeConfig = useRuntimeConfig()
	const baseURL = `${runtimeConfig.public.baseUrl.forms}/${globalThis.incrowd.clientId}/forms/`
	contentFetch = createFetch({ baseURL })
}
const formFetch = (url: string, options?: Omit<ContentFetchOptions, 'dataMapper' | 'params'>) => {
	init()
	return contentFetch(url, options)
}
export const getForm = async (formId: string, options?: Omit<ContentFetchOptions, 'dataMapper' | 'params'>): Promise<Form> => {
	return await formFetch(formId, options)
}

export const postForm = async (formId: string, email: string, fieldId:string) => {
	const runtimeConfig = useRuntimeConfig()
	const url = `${runtimeConfig.public.baseUrl.forms}/${globalThis.incrowd.clientId}/forms/${formId}`
	try {
		const response = await useFetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: {
				confirmationAddress: email,
				fields: [
					{
						id: fieldId,
						value: email,
					},
				],
			},
		})

		if (response.data.value?.status === 'success') {
			return response.data.value
		} else {
			return response?.error?.value?.data.data.errors[0].errorMessage
		}
	} catch (error) {
		console.log('error message', error)
	}
}
